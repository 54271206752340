
import {
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonCol,
    IonContent,
    IonGrid,
    IonPage,
    IonRow,
    onIonViewDidEnter,
    IonButton,
} from '@ionic/vue';
import {defineComponent} from 'vue'
import Header from "@/components/Header.vue";
import {activeResultPointList, fetchResultPoints, getActiveRatings} from "@/util/ResultService";
import {Cheese} from "@/util/CheeseService";
import {currentContest} from "@/util/ContestService";
import {loggedInJuror} from "@/util/JuryService";

export default defineComponent({
    name: "Rating",
    components: {
        Header,
        IonPage,
        IonGrid,
        IonRow,
        IonCol,
        IonContent,
        IonCard,
        IonCardContent,
        IonCardHeader,
        IonCardTitle,
        IonButton,
    },
    setup() {
        onIonViewDidEnter(() => {
            fetchResultPoints()
            getActiveRatings()
        });
    },
    methods: {
        getCheeseInfos(id: number): Cheese | null {
            return currentContest.value?.getParticipant(id)
        },
        openRating(participant: Cheese){
            if(participant.innovation){
                this.$router.push('/rating/innovation/' + participant.id + '?origin=openratings')
            }else{
                this.$router.push('/rating/cheese/' + participant.id + '?origin=openratings')
            }
        }
    },
    computed: {
        getActiveResultPointList() {
            return activeResultPointList.value
        },
        getJurorName(){
            if ( loggedInJuror.value) {
                return 'Juror: ' + loggedInJuror.value._firstName +' '+ loggedInJuror.value._lastName
            }
            return ''
        }
    }
})
